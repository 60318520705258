import React from "react";
import {Menu, Dropdown} from "antd";
import {observer, inject} from "mobx-react";

import {RIGHT_SYMBOL, TEMPLATE_NUM, MARKDOWN_THEME_ID, THEME_LIST, STYLE, THEME_API, TOKEN} from "../../utils/constant";
import {replaceStyle} from "../../utils/helper";
import TEMPLATE from "../../template/index";
import "./Theme.css";
import axios from "axios";

@inject("content")
@inject("navbar")
@inject("view")
@observer
class Theme extends React.Component {
  changeTemplate = (item) => {
    const index = parseInt(item.key, 10);
    const {themeId, css} = this.props.content.themeList[index];
    this.props.navbar.setTemplateNum(index);

    // 更新style编辑器
    if (themeId === "custom") {
      this.props.content.setCustomStyle();
      // 切换自定义自动打开css编辑
      this.props.view.setStyleEditorOpen(true);
    } else {
      this.props.content.setStyle(css);
    }
  };

  toggleStyleEditor = () => {
    const {isStyleEditorOpen} = this.props.view;
    this.props.view.setStyleEditorOpen(!isStyleEditorOpen);
  };

  subscribeMore = () => {
    const w = window.open("about:blank");
    w.location.href = "https://preview.mdnice.com/themes";
  };

  componentDidMount = async () => {
    let themeList = null;
    try {
      const {token} = this.props;
      let response;
      let remoteThemelist;
      // if (token) {
      //   // 如果处于登录状态，则读取订阅的主题
      //   response = await axios.get(`https://api.mdnice.com/themes/editor`, {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   });
      //   if (!response.data.success) {
      //     throw new Error();
      //   }
      //   remoteThemelist = response.data.data;
      // } else {
      //   // 否则默认主题
      //   response = await axios.get(THEME_API());
      //   if (!response.data.success) {
      //     throw new Error();
      //   }
      //   remoteThemelist = response.data.data.themeList;
      // }
      remoteThemelist = [
        {
            "themeId": 3060,
            "coinItemId": null,
            "price": null,
            "name": "重影",
            "cover": "https://files.mdnice.com/pic/ac71ef9d-8e18-42dd-82ba-d223881a5fb8.png",
            "writingOutId": "15ae25bec1c14c9cb92a8e1c882e342e",
            "applicantUsername": "优秀的大鹏",
            "description": "看标题就有视觉模糊，谍影重重的感觉",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.chongying
        },
        {
            "themeId": 3050,
            "coinItemId": null,
            "price": null,
            "name": "丘比特忙",
            "cover": "https://files.mdnice.com/pic/46bc9201-ab76-4d54-9fa6-127869a5ba94.png",
            "writingOutId": "fe40302d1f254c20ab4655f35d75201f",
            "applicantUsername": "优秀的大鹏",
            "description": "丘比特忙是优秀单身交友平台，此主题为专用主题",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.qiubitemang
        },
        {
            "themeId": 1377,
            "coinItemId": 7,
            "price": 100,
            "name": "奇点",
            "cover": "https://files.mdnice.com/pic/1a39d465-fc26-4008-901c-5a6b6ba12a45.png",
            "writingOutId": "2305da6794a8415ba93843f4f4a3bbd5",
            "applicantUsername": "倪豪",
            "description": "该主题源于【奇点读书会】，故而名为奇点",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.qidian
        },
        {
            "themeId": 1348,
            "coinItemId": null,
            "price": null,
            "name": "雁栖湖",
            "cover": "https://files.mdnice.com/pic/af325a49-1e79-4a50-be03-ea24a4d88720.png",
            "writingOutId": "2e2c93c712964be29d88b01a13447654",
            "applicantUsername": "优秀的大鹏",
            "description": "作者于中国科学院大学雁栖湖校区上学时排版的一篇文章，故而取名雁栖湖",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.yanxihu
        },
        {
            "themeId": 1,
            "coinItemId": null,
            "price": null,
            "name": "橙心",
            "cover": "https://files.mdnice.com/pic/9322d897-85d5-4be1-9c9d-c392d4d39bff.png",
            "writingOutId": "2a03196c5a654c60ab286b8966a98fd7",
            "applicantUsername": "优秀的大鹏",
            "description": "橙心的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.chengxin
        },
        {
            "themeId": 3,
            "coinItemId": null,
            "price": null,
            "name": "姹紫",
            "cover": "https://files.mdnice.com/pic/e989865f-32c6-4201-a486-9f85fe7c2fab.png",
            "writingOutId": "81049836197d4666b5396e22451837f0",
            "applicantUsername": "一淼",
            "description": "我喜欢极简风格晔喜欢这个紫色，清晰有力而且也很有魅力。也是做很多年设计师后，发现很好用的颜色。",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.chazi
        },
        {
            "themeId": 4,
            "coinItemId": null,
            "price": null,
            "name": "嫩青",
            "cover": "https://files.mdnice.com/pic/94cff81b-a086-46aa-a70e-1b9771b67cfc.png",
            "writingOutId": "f74434ef5618403e8825f464bc47284d",
            "applicantUsername": "优秀的大鹏",
            "description": "嫩青的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.nenqing
        },
        {
            "themeId": 5,
            "coinItemId": null,
            "price": null,
            "name": "绿意",
            "cover": "https://files.mdnice.com/pic/02f3f593-3380-4aa6-9d90-6e2b6f276558.png",
            "writingOutId": "f227099510d54882a5f872a9a1ce7900",
            "applicantUsername": "夜尽天明",
            "description": "绿意的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.lvyi
        },
        {
            "themeId": 6,
            "coinItemId": null,
            "price": null,
            "name": "红绯",
            "cover": "https://files.mdnice.com/pic/9f008265-8511-4f9c-aafd-87027c94bd58.png",
            "writingOutId": "106386a09b6a474cbdde586420f23f27",
            "applicantUsername": "HeyRain",
            "description": "红绯的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.hongfei
        },
        {
            "themeId": 8,
            "coinItemId": null,
            "price": null,
            "name": "蓝莹",
            "cover": "https://files.mdnice.com/pic/248727f4-d81c-42a3-9723-3a1e5bdbda30.png",
            "writingOutId": "8d877ef9bdbe452a9f779e919aef2d7b",
            "applicantUsername": "谭淞宸",
            "description": "蓝莹的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.lanying
        },
        {
            "themeId": 10,
            "coinItemId": null,
            "price": null,
            "name": "兰青",
            "cover": "https://files.mdnice.com/pic/c65c74bf-37c7-4b0b-97b9-1c8c47615535.png",
            "writingOutId": "1bd7de291ea14f9d890698e6f7030bf7",
            "applicantUsername": "krahets",
            "description": "兰青的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.lanqing
        },
        {
            "themeId": 11,
            "coinItemId": null,
            "price": null,
            "name": "山吹",
            "cover": "https://files.mdnice.com/pic/2d041c6f-564b-4392-9636-9998f2b056bb.png",
            "writingOutId": "4cf91d81def645d5981e2938caca2104",
            "applicantUsername": "优秀的大鹏",
            "description": "山吹的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.shanchui
        },
        {
            "themeId": 12,
            "coinItemId": null,
            "price": null,
            "name": "前端之巅同款",
            "cover": "https://files.mdnice.com/pic/473ace64-588c-4bcb-9ef3-0b005c78c1f2.png",
            "writingOutId": "7c4ebc4b7761493ca8e2a6b048bbeb08",
            "applicantUsername": "HeyRain",
            "description": "前端之巅的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.qianduanzhidian
        },
        {
            "themeId": 13,
            "coinItemId": null,
            "price": null,
            "name": "极客黑",
            "cover": "https://files.mdnice.com/pic/d160f5ec-26cb-4de8-8509-a8b29e8dd20c.png",
            "writingOutId": "403fa6efbcdd46bb9827d5802b3d9906",
            "applicantUsername": "萌萌",
            "description": "极客黑的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.jikehei
        },
        {
            "themeId": 15,
            "coinItemId": null,
            "price": null,
            "name": "蔷薇紫",
            "cover": "https://files.mdnice.com/pic/06aa2d77-0a16-44c4-93f2-3aff4c67e095.png",
            "writingOutId": "2fa4f1581f6c45adb62aec71b8973d53",
            "applicantUsername": "HeyRain",
            "description": "蔷薇紫的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.qiangweizi
        },
        {
            "themeId": 16,
            "coinItemId": null,
            "price": null,
            "name": "萌绿",
            "cover": "https://files.mdnice.com/pic/168a4408-be49-4706-826f-b63c001f8ba3.png",
            "writingOutId": "5337a71d499f4f1c9d21f95432397603",
            "applicantUsername": "Koala",
            "description": "萌绿的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.menglv
        },
        {
            "themeId": 20,
            "coinItemId": null,
            "price": null,
            "name": "清新绿",
            "cover": "https://files.mdnice.com/pic/168a4408-be49-4706-826f-b63c001f8ba3.png",
            "writingOutId": "5337a71d499f4f1c9d21f95432397603",
            "applicantUsername": "Koala",
            "description": "清新绿的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.qingxinlv
        },
        {
            "themeId": 17,
            "coinItemId": null,
            "price": null,
            "name": "全栈蓝",
            "cover": "https://files.mdnice.com/pic/39cc0084-e0ca-47e3-b661-6c00c61c23b5.png",
            "writingOutId": "633391e3fb264962bb886824dc24ff6c",
            "applicantUsername": "Nealyang",
            "description": "全栈蓝的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.quanzhanlan
        },
        {
            "themeId": 18,
            "coinItemId": null,
            "price": null,
            "name": "极简黑",
            "cover": "https://files.mdnice.com/pic/d4e91acf-c904-4f2d-9ddd-a6bd275bc54b.png",
            "writingOutId": "02b9aef123d5424d90904225433dd8f1",
            "applicantUsername": "小鱼",
            "description": "公众号自律神仙ScarSu同款~",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.jijianhei
        },
        {
            "themeId": 19,
            "coinItemId": null,
            "price": null,
            "name": "橙蓝风",
            "cover": "https://files.mdnice.com/pic/31204a5a-254e-487e-879d-4546ebcce3c3.jpg",
            "writingOutId": "0c0bcaa501f84712ab5c59103e405ce7",
            "applicantUsername": "Bin",
            "description": "简洁的橙蓝风",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.chenglanfeng
        },
        {
            "themeId": 33,
            "coinItemId": null,
            "price": null,
            "name": "Pornhub黄",
            "cover": "https://files.mdnice.com/pic/87c1226f-0788-4d26-8a69-e7be82f70932.png",
            "writingOutId": "00d9eceb6ef74a2d84a995f795a57742",
            "applicantUsername": "vitamin不吃维他命",
            "description": "采用经典的Pornhub黄黑配色，并采用Ant-design的设计规范，让你更加专注于MD本身。",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.pornhubhuang
        },
        {
            "themeId": 35,
            "coinItemId": null,
            "price": null,
            "name": "凝夜紫",
            "cover": "https://files.mdnice.com/pic/73c130f0-a866-45f0-8d96-ecb3bb33da32.jpg",
            "writingOutId": "a6b2531e05564adfb6fced02348afda5",
            "applicantUsername": "童欧巴",
            "description": "适配微信以及Safari的深色模式。“凝夜紫”，寓意在深色模式中也可以发光。",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.ningyezi
        },
        {
            "themeId": 42,
            "coinItemId": null,
            "price": null,
            "name": "萌粉",
            "cover": "https://files.mdnice.com/pic/d3b2bc3d-c2a2-4a0f-9c34-931e61af1579.png",
            "writingOutId": "80e68ef58c724b7bac4ecca200345c34",
            "applicantUsername": "上官追风",
            "description": "可爱的萌粉",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.mengfen
        },
        {
            "themeId": 44,
            "coinItemId": null,
            "price": null,
            "name": "Obsidian",
            "cover": "https://files.mdnice.com/pic/1b3a6388-8338-430a-9b6c-f7bb84b3e718.png",
            "writingOutId": "3007da57f8ce4152b70b7e40cd246f60",
            "applicantUsername": "三钻",
            "description": "一款暗色的Hexo主题, 简约而优雅.",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.obsidian
        },
        {
            "themeId": 45,
            "coinItemId": null,
            "price": null,
            "name": "灵动蓝",
            "cover": "https://files.mdnice.com/pic/31016bf3-0a07-4ebe-8ded-9999a714ea90.png",
            "writingOutId": "54536036a9274975bad515cf99fdabcd",
            "applicantUsername": "Tusi",
            "description": "支持自定义标题背景图透明度，可以在这个基础上换自己的logo或者背景图，免去了ps修改透明度的麻烦。",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.lingdonglan
        },
        {
            "themeId": 48,
            "coinItemId": null,
            "price": null,
            "name": "草原绿",
            "cover": "https://files.mdnice.com/pic/37bae771-dfed-49d0-bda7-21aed462acc2.jpg",
            "writingOutId": "da8ada9ee557460bbcc173124a1850cb",
            "applicantUsername": "超人",
            "description": "绿油油的大草原",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.caoyuanlv
        },
        {
            "themeId": 51,
            "coinItemId": null,
            "price": null,
            "name": "科技蓝",
            "cover": "https://files.mdnice.com/pic/1919fe6a-4e84-4365-bd94-eb66e592c04a.png",
            "writingOutId": "1dc0852b476b44d2a46d7e5dc78d1302",
            "applicantUsername": "夜尽天明",
            "description": "科技蓝的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.kejilan
        },
        {
            "themeId": 62,
            "coinItemId": null,
            "price": null,
            "name": "WeFormat",
            "cover": "https://files.mdnice.com/pic/7c426155-27e8-493b-a483-ce966884a8b9.png",
            "writingOutId": "e5d45472567e48cdb12758b9923a10f9",
            "applicantUsername": "优秀的大鹏",
            "description": "Wechat Format的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.weformat
        },
        {
            "themeId": 63,
            "coinItemId": null,
            "price": null,
            "name": "简",
            "cover": "https://files.mdnice.com/pic/0cf8e3fd-fb8a-4b29-a2d9-d729bc424392.png",
            "writingOutId": "b42243d6fdef4c2a8a0daebd36834c5f",
            "applicantUsername": "萌萌",
            "description": "简的作者很懒，暂时没有描述",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.jian
        },
        {
            "themeId": 1653,
            "coinItemId": null,
            "price": null,
            "name": "锤子便签主题第2版",
            "cover": "https://files.mdnice.com/pic/c781d9a9-6116-4f60-9eac-f2132844b73d.png",
            "writingOutId": "99e5279d975149a18bd71ec87dbc96a0",
            "applicantUsername": "老法师昭昭",
            "description": "锤子便签主题：不要因为走的太远，就忘记了为什么出发",
            "isPublic": true,
            "hasCollect": false,
            "hasPay": false,
            "isApplicant": false,
            "isDefaultTheme": false,
            "css": TEMPLATE.chuizibianqian
        }
    ];

      themeList = [
        {themeId: "normal", name: "默认主题", css: TEMPLATE.normal},
        ...remoteThemelist,
        {themeId: "custom", name: "自定义", css: TEMPLATE.custom},
      ];
      console.log(themeList)
      this.props.content.setThemeList(themeList);
    } catch (err) {
      console.error("读取最新主题信息错误");
      // 降级方案：使用本地的值
      themeList = JSON.parse(window.localStorage.getItem(THEME_LIST));
      this.props.content.setThemeList(themeList);
    }

    const templateNum = parseInt(window.localStorage.getItem(TEMPLATE_NUM), 10);

    // 主题样式初始化，属于自定义主题则从localstorage中读数据
    let style = "";
    if (templateNum === themeList.length - 1) {
      style = window.localStorage.getItem(STYLE);
    } else {
      if (templateNum) {
        const {css} = themeList[templateNum];
        style = css;
      } else {
        style = TEMPLATE.normal;
      }
    }
    this.props.content.setStyle(style);
    replaceStyle(MARKDOWN_THEME_ID, style);
  };

  render() {
    const {templateNum} = this.props.navbar;
    const {themeList} = this.props.content;

    const mdMenu = (
      <Menu onClick={this.changeTemplate}>
        {themeList.map((option, index) => (
          <Menu.Item key={index}>
            <div id={`nice-menu-theme-${option.themeId}`} className="nice-themeselect-theme-item">
              <span>
                <span className="nice-themeselect-theme-item-flag">
                  {templateNum === index && <span>{RIGHT_SYMBOL}</span>}
                </span>
                <span className="nice-themeselect-theme-item-name">{option.name}</span>
                {option.isNew && <span className="nice-themeselect-theme-item-new">new</span>}
              </span>
              <span className="nice-themeselect-theme-item-author">{option.authorName}</span>
            </div>
          </Menu.Item>
        ))}
        <Menu.Divider />
        <li className="nice-themeselect-menu-item">
          <div id="nice-menu-subscribe-more" className="nice-themeselect-theme-item" onClick={this.subscribeMore}>
            <span>
              <span className="nice-themeselect-theme-item-flag" />
              <span className="nice-themeselect-theme-item-name nice-menu-subscribe-more">订阅更多主题</span>
            </span>
          </div>
        </li>
        <Menu.Divider />

        <li className="nice-themeselect-menu-item">
          <div id="nice-menu-view-css" className="nice-themeselect-theme-item" onClick={this.toggleStyleEditor}>
            <span>
              <span className="nice-themeselect-theme-item-flag">
                {this.props.view.isStyleEditorOpen && <span>{RIGHT_SYMBOL}</span>}
              </span>
              <span className="nice-themeselect-theme-item-name">查看主题 CSS</span>
            </span>
          </div>
        </li>
      </Menu>
    );
    return (
      <Dropdown overlay={mdMenu} trigger={["click"]} overlayClassName="nice-overlay">
        <a id="nice-menu-theme" className="nice-menu-link" href="#">
          主题
        </a>
      </Dropdown>
    );
  }
}

export default Theme;
