import custom from "./markdown/custom";
import normal from "./markdown/normal";

import caoyuanlv from "./markdown/caoyuanlv";
import chazi from "./markdown/chazi";
import chenglanfeng from "./markdown/chenglanfeng";
import chengxin from "./markdown/chengxin";
import chongying from "./markdown/chongying";
import chuizibianqian from "./markdown/chuizibianqian";
import hongfei from "./markdown/hongfei";
import jian from "./markdown/jian";
import jijianhei from "./markdown/jijianhei";
import jikehei from "./markdown/jikehei";
import kejilan from "./markdown/kejilan";
import lanqing from "./markdown/lanqing";
import lanying from "./markdown/lanying";
import lingdonglan from "./markdown/lingdonglan";
import lvyi from "./markdown/lvyi";
import mengfen from "./markdown/mengfen";
import menglv from "./markdown/menglv";
import nenqing from "./markdown/nenqing";
import ningyezi from "./markdown/ningyezi";
import obsidian from "./markdown/obsidian";
import purnhubhuang from "./markdown/purnhubhuang";
import qianduanzhidian from "./markdown/qianduanzhidian";
import qiangweizi from "./markdown/qiangweizi";
import qiubitemang from "./markdown/qiubitemang";
import quanzhanlan from "./markdown/quanzhanlan";
import shanchui from "./markdown/shanchui";
import weformat from "./markdown/weformat";
import yanxihu from "./markdown/yanxihu";
import qingxinlv from "./markdown/qingxinlv";

import content from "./content.md";

import atomOneDark from "./code/atomOneDark";
import atomOneLight from "./code/atomOneLight";
import github from "./code/github";
import monokai from "./code/monokai";
import vs2015 from "./code/vs2015";
import xcode from "./code/xcode";

import macAtomOneDark from "./macCode/macAtomOneDark";
import macAtomOneLight from "./macCode/macAtomOneLight";
import macGithub from "./macCode/macGithub";
import macMonokai from "./macCode/macMonokai";
import macVs2015 from "./macCode/macVs2015";
import macXcode from "./macCode/macXcode";

import basic from "./basic";

export default {
  basic,
  normal,
  custom,
  caoyuanlv, 
  chazi,
  chenglanfeng,
  chengxin,
  chongying, 
  chuizibianqian,
  hongfei,
  jian,
  jijianhei,
  jikehei,
  kejilan,
  lanqing,
  lanying,
  lingdonglan,
  lvyi,
  mengfen,
  menglv,
  nenqing,
  ningyezi,
  obsidian,
  purnhubhuang,
  qianduanzhidian,
  qiangweizi,
  qiubitemang,
  quanzhanlan,
  shanchui,
  weformat,
  yanxihu,
  qingxinlv,
  code: {
    atomOneDark,
    atomOneLight,
    github,
    monokai,
    vs2015,
    xcode,
    macAtomOneDark,
    macAtomOneLight,
    macGithub,
    macMonokai,
    macVs2015,
    macXcode,
  },
  content,
};
