export default `/* 自定义样式，实时生效，浏览器实时缓存 */

/* 全局属性
 * 页边距 padding: 30px;
 * 全文字体 font-family: ptima-Regular;
 * 英文换行 word-break: break-all;
 */

.nice-wx-box{
  padding: 0;
  margin:0;
}

#nice {
  background-color: #fbf7ee;
    /* 注：请在大括号内改写！！！ */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji' !important;
  color: #635753 !important;
  padding: 10px 10px;
}

/* 段落，下方未标注标签参数均同此处
 * 上边距 margin-top: 5px;
 * 下边距 margin-bottom: 5px;
 * 行高 line-height: 26px;
 * 词间距 word-spacing: 3px;
 * 字间距 letter-spacing: 3px;
 * 对齐 text-align: left;
 * 颜色 color: #3e3e3e;
 * 字体大小 font-size: 16px;
 * 首行缩进 text-indent: 2em;
 */
#nice p {
  	color: #635753;
    /* 注：请在大括号内改写！！！ */
  line-height: 2
}

/* 一级标题 */
#nice h1 {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 一级标题内容 */
#nice h1 .content {
  color: #635753;
    /* 注：请在大括号内改写！！！ */
}

/* 一级标题前缀 */
#nice h1 .prefix {
  color: #635753;
    /* 注：请在大括号内改写！！！ */
}

/* 一级标题后缀 */
#nice h1 .suffix {
  color: #635753;
    /* 注：请在大括号内改写！！！ */
}

/* 二级标题 */
#nice h2 {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 二级标题内容 */
#nice h2 .content {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 二级标题前缀 */
#nice h2 .prefix {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 二级标题后缀 */
#nice h2 .suffix {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 三级标题 */
#nice h3 {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 三级标题内容 */
#nice h3 .content {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 三级标题前缀 */
#nice h3 .prefix {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 三级标题后缀 */
#nice h3 .suffix {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 无序列表整体样式
 * list-style-type: square|circle|disc;
 */
#nice ul {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 有序列表整体样式
 * list-style-type: upper-roman|lower-greek|lower-alpha;
 */
#nice ol {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 列表内容，不要设置li
 */
#nice li section {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 一级引用
 * 左边缘颜色 border-left-color: black;
 * 背景色 background: gray;
 */
#nice .multiquote-1 {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 一级引用文字 */
#nice .multiquote-1 p {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 二级引用
 */
#nice .multiquote-2 {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 二级引用文字 */
#nice .multiquote-2 p {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 三级引用
 */
#nice .multiquote-3 {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 三级引用文字 */
#nice .multiquote-3 p {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 链接 
 * border-bottom: 1px solid #009688;
 */
#nice a {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
  border-bottom: 1px solid #ebdfd5;
}

/* 加粗 */
#nice strong {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 斜体 */
#nice em {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 加粗斜体 */
#nice em strong {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 删除线 */
#nice del {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 分隔线
 * 粗细、样式和颜色
 * border-top: 1px solid #3e3e3e;
 */
#nice hr {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 图片
 * 宽度 width: 80%;
 * 居中 margin: 0 auto;
 * 居左 margin: 0 0;
 */
#nice img {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 行内代码 */
#nice p code, #nice li code {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
 
}

/* 
 * 代码块不换行 display: -webkit-box !important;
 * 代码块换行 display: block;
 */
#nice pre code {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
  
}

/*
 * 表格内的单元格
 * 字体大小 font-size: 16px;
 * 边框 border: 1px solid #ccc;
 * 内边距 padding: 5px 10px;
 */
#nice table tr th,
#nice table tr td {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 
 * 某一列表格列宽控制
 * n 可以修改为具体数字，不修改时表示所有列
 * 最小列宽 min-width: 85px;
 */
#nice table tr th:nth-of-type(n),
#nice table tr td:nth-of-type(n){
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 脚注文字 */
#nice .footnote-word {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 脚注上标 */
#nice .footnote-ref {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* "参考资料"四个字 
 * 内容 content: "参考资料";
 */
#nice .footnotes-sep:before {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 参考资料编号 */
#nice .footnote-num {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 参考资料文字 */
#nice .footnote-item p { 
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 参考资料解释 */
#nice .footnote-item p em {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 行间公式
 * 最大宽度 max-width: 300% !important;
 */
#nice .block-equation svg {
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

/* 行内公式
 */
#nice .inline-equation svg {  
    /* 注：请在大括号内改写！！！ */
  color: #635753;
}

img{
  width: 100%;
  box-shadow: 14px 14px 28px #e6e6e6, -14px -14px 28px #fff;
  border: 9px solid #ffffff;
  outline: 1px solid #ebdfd5;

}

.custom::before {
 background-color: #fbf7ee !important;
  
}

code {
  background-color: #fbf7ee !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji' !important;
}`;
