export default `/*自定义样式，实时生效*/

/* 全局属性
 * 页边距 padding:30px;
 * 全文字体 font-family:ptima-Regular;
 * 英文换行 word-break:break-all;
 * PingFang在iOS上很好看;
 */
#nice {
  font-family:PingFangSC-Light;
  font-size:16px;
  padding:10px;
}

/* 段落，下方未标注标签参数均同此处
 * 上边距 margin-top:5px;
 * 下边距 margin-bottom:5px;
 * 行高 line-height:28px;
 * 词间距 word-spacing:0.1em;
 * 字间距 letter-spacing:0.1em;
 * 对齐-两端对齐 text-align:justify;
 * 颜色 color:rgba(0, 0, 0, 0.65);
 * 字体大小 font-size:16px;
 * 首行缩进 text-indent:2em;
 */
#nice p {
  margin:5px 0px;
  line-height:1.75;
  letter-spacing:0.1em;
  font-size:16px;
  word-spacing:0.1em;
  text-align:justify;
  color:rgba(0, 0, 0, 0.85);
}



/* 一级标题，全文只有一个*/
#nice h1 {
  text-align:center;
  /* 后续可以定制化图形
  background-image: url(http://xxx.png);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 75px;
  */
  line-height:95px;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* 一级标题内容 */
#nice h1 .content {
  font-size: 22px;
  color: #f48a00;
  border-bottom:2px solid #ffbf52;
}

/* 一级标题修饰 请参考有实例的主题 */
#nice h1:after {
}
 
/* 二级标题 */
#nice h2 {
  text-align:left;
  margin:20px 0px 0px 0px;
}

/* 二级标题内容 */
#nice h2 .content {
  font-size:20px;
  color: #f48a00;;
  display:inline-block;
  padding-left:10px;
  border-left:8px solid #ffbf52;
}

/* 二级标题修饰 请参考有实例的主题 */
#nice h2:after {
}

/* 三级标题 */
#nice h3 {
  text-align:left;
  margin:15px 0px 0px 0px;
}

/* 三级标题内容 */
#nice h3 .content {
  font-size:18px;
  color: #f48a00;;
  display:inline-block;
  padding-left:10px;
  border-left:4px solid #ffe3a3;
}

/* 三级标题修饰 请参考有实例的主题 */
#nice h3:after {
}

/* 如果按层级顺序使用，建议不要用到四级标题
	 如果是做序列使用，则直接用四级就可以
*/
#nice h4 {
 text-align:left;
  margin:10px 0px 0px 0px;
}

/* 四级标题内容 */
#nice h4 .content {
  font-size:16px;
  color:#f48a00;
  display:inline-block;
}

/* 四级标题修饰 请参考有实例的主题 */
#nice h4:after {
}

/* 无序列表整体样式
 * list-style-type: square|circle|disc;
 */
#nice ul {
  font-size: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 20px;
  color: #ffbf52;
}

/* 有序列表整体样式
 * list-style-type: upper-roman|lower-greek|lower-alpha;
 */
#nice ol {
  font-size: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 20px;
  color: #ffbf52;
}

/* 列表内容，不要设置li
 */
#nice li section {
  letter-spacing:0.1em;
  word-spacing:0.1em;
  font-size:15px;
  color:rgb(0,0,0,0.55);
}

/* 引用
* 左边缘颜色 border-left-color:black;
* 背景色 background:gray;
*/
#nice .multiquote-1::before {
  content: "❝";
  color: #f48a00;
  font-size: 32px;
  line-height: 0.6;
  margin-left: -15px;
}

#nice .multiquote-1 {
  text-size-adjust: 100%;
  line-height: 1.75em;
  font-weight: 400;
  border-radius: 5px;
  font-style: normal;
  text-align: left;
  box-sizing: inherit;
  border-left: none;
  border: 1px solid #ffbf52;
  background: #fff8e6;

}

#nice .multiquote-1 p {
  margin-top:-15px;
  color: rgb(0,0,0,0.85);
}

#nice .multiquote-1::after {
  
}

/* 链接 
 * border-bottom: 1px solid #009688;
 */
#nice a {
  color:#f48a00;
  border-bottom:1px solid #f48a00;
  font-family:STHeitiSC-Light;
  font-weight:normal;
}

/* 加粗 */
#nice strong {
  font-weight:bold;
  color:rgba(0, 0, 0, 0.85);
}

/* 斜体 */
#nice em {
  color:rgba(0, 0, 0, 0.85);
  letter-spacing:0.1em;
}

/* 加粗斜体 */
#nice em strong {
  font-style: normal;
  color:#f48a00;
  font-weight:bold;
  letter-spacing:0.1em;
}

/* 删除线 */
#nice del {
}
 
/* 分隔线
 * 粗细、样式和颜色
 * border-top:1px solid #3e3e3e;
 */
#nice hr {
  height:2px;
  padding:0;
  margin: 20px 0;
  border:none;
  border-top:medium solidid #333;
  text-align:center;
  background-image:linear-gradient(to right,rgba(248,57,41,0),#f48a00,rgba(248,57,41,0));
}

/* 图片
 * 宽度 width:100%;
 * 居中 margin:0 auto;
 * 居左 margin:0 0;
 */
#nice img {
  border-radius:5px 5px 5px 5px;
  display:block;
  margin:0px 10px auto;
  width:100%;
  height:100%;
  object-fit:contain;
  /*box-shadow: #ede0d0 0px 6px 20px;*/
}

/* 图片描述文字 */
#nice figcaption {
  display:block;
  font-size:13px;
  color:rgba(0,0,0,0.55);
  text-align:right;
  font-family:PingFangSC-Light;
}

/* 行内代码 */
#nice p code, #nice li code {
  color:#f48a00;
}


/* 非微信代码块
 * 代码块不换行 display:-webkit-box !important;
 * 代码块换行 display:block;
 */
#nice pre code {
}

/*
 * 表格内的单元格
 * 字体大小 font-size: 16px;
 * 边框 border: 1px solid #ccc;
 * 内边距 padding: 5px 10px;
 */
#nice table tr th,
#nice table tr td {
  font-size: 14px;
}

/* 脚注文字 */
#nice .footnote-word {
  color:#f48a00;
}

/* 脚注上标 */
#nice .footnote-ref {
  color:#f48a00;
}

/*脚注链接样式*/
#nice .footnote-item em {
  font-style:normal;
  color:rgba(0,0,0,0.65);
  font-size:13px;
}

/* "参考资料"四个字 
 * 内容 content: "参考资料";
 */
#nice .footnotes-sep:before {
}

/* 参考资料编号 */
#nice .footnote-num {
  color:#f48a00;
}

/* 参考资料文字 */
#nice .footnote-item p { 
 color:#f48a00;
}

/* 参考资料解释 */
#nice .footnote-item p em {
}

/* 行间公式
 * 最大宽度 max-width: 300% !important;
 */
#nice .block-equation svg {
}

/* 行内公式
 */
#nice .inline-equation svg {  
}`;
