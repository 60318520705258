export default `/*自定义样式，实时生效*/

/*自定义样式，实时生效*/

/* 全局属性
* 页边距 padding:30px;
* 全文字体 font-family:optima-Regular;
* 英文换行 word-break:break-all;
color:#2b2b2b;
*/
#nice {
  line-height: 1.35;
  color: #333;
  font-family: Optima-Regular, PingFangTC-light;
  letter-spacing: 1.5px;
}

/* 段落，下方未标注标签参数均同此处
* 上边距 margin-top:5px;
* 下边距 margin-bottom:5px;
* 行高 line-height:26px;
* 词间距 word-spacing:3px;
* 字间距 letter-spacing:3px;
* 对齐 text-align:left;
* 颜色 color:#3e3e3e;
* 字体大小 font-size:16px;
* 首行缩进 text-indent:2em;
*/
#nice p {
  color: #2b2b2b;
  margin: 10px 0px;
  letter-spacing: 2px;
  font-size: 16px;
  word-spacing: 2px;
}

/* 一级标题 */
#nice h1 {
  font-size: 25px;
}

/* 一级标题内容 */
#nice h1 span {
  display: inline-block;
  font-weight: bold;
  color: #4CAF50;
}

/* 一级标题修饰 请参考有实例的主题 */
#nice h1:after {}

/* 二级标题 */
#nice h2 {
  display:block;
  border-bottom: 4px solid #4CAF50;
}

/* 二级标题内容 */
#nice h2 .content {
  display: flex;
  color: #4CAF50;
  font-size: 20px;

}

/* 二级标题前缀 */
#nice h2 .prefix {

}

/* 二级标题后缀 */
#nice h2 .suffix {
  display: flex;
  box-sizing: border-box;
  width: 20px;
  height: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: RGBA(76, 175, 80, .5);
  color: rgb(255, 255, 255);
  font-size: 16px;
  letter-spacing: 0.544px;
  justify-content: flex-end;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  float: right;
  margin-top: -10px;
}

/* 三级标题 */
#nice h3 {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  position:relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* 三级标题内容 */
#nice h3 .content {
  color: #2b2b2b;
  padding-bottom:2px
}

#nice h3 .content:before{
  content:'';
  width:30px;
  height:30px;
  display:block;
  background-image:url(https://files.mdnice.com/grass-green.png);
  background-position:center;
  background-size:30px;
  margin:auto;
  opacity:1;
  background-repeat:no-repeat;
  margin-bottom:-8px;
}

/* 三级标题修饰 请参考有实例的主题 */
#nice h3:after {}

#nice h4 .content {
  height:16px;
  line-height:16px;
  font-size: 16px;
}

#nice h4 .content:before{

}

/* 无序列表整体样式
* list-style-type: square|circle|disc;
*/
#nice ul {
  font-size: 15px; /*神奇逻辑，必须比li section的字体大才会在二级中生效*/
  color: #595959;
  list-style-type: circle;
}


/* 有序列表整体样式
* list-style-type: upper-roman|lower-greek|lower-alpha;
*/
#nice ol {
  font-size: 15px;
  color: #595959;
}

/* 列表内容，不要设置li
*/
#nice li section {
  font-size: 16px;
  font-weight: normal;
  color: #595959;
}

/* 引用
* 左边缘颜色 border-left-color:black;
* 背景色 background:gray;
*/
#nice .multiquote-1::before {
  content: "❝";
  color: #74b56d;
  font-size: 34px;
  line-height: 1;
  font-weight: 700;
}

#nice .multiquote-1 {
  text-size-adjust: 100%;
  line-height: 1.55em;
  font-weight: 400;
  border-radius: 6px;
  color: #595959;
  font-style: normal;
  text-align: left;
  box-sizing: inherit;
  border-left: none;
  border: 1px solid #1b900d;
  background: #fff;

}

#nice .multiquote-1 p {

}

#nice .multiquote-1::after {
  content: "❞";
  float: right;
  color: #74b56d;
}

/* 链接
* border-bottom: 1px solid #009688;
*/
#nice a {
  color: #399003;
  font-weight: normal;
  border-bottom: 1px solid #399003;
}

#nice strong::before {
  content: '「';
}

/* 加粗 */
#nice strong {
  color: #399003;
  font-weight: bold;
}

#nice strong::after {
  content: '」';
}

/* 斜体 */
#nice em {
  font-style: normal;
  color: #399003;
  font-weight:bold;
}

/* 加粗斜体 */
#nice em strong {
  color: #399003;
}

/* 删除线 */
#nice del {
  color: #399003;
}

/* 分隔线
* 粗细、样式和颜色
* border-top:1px solid #3e3e3e;
*/
#nice hr {
  height: 1px;
  padding: 0;
  border: none;
  border-top: 2px solid #399003;
}

/* 图片
* 宽度 width:80%;
* 居中 margin:0 auto;
* 居左 margin:0 0;
*/
#nice img {
  border-radius: 6px;
  display: block;
  margin: 20px auto;
  object-fit: contain;
  box-shadow:2px 4px 7px #999;
}

/* 图片描述文字 */
#nice figcaption {
  display: block;
  font-size: 13px;
  color: #2b2b2b;
}

/* 行内代码 */
#nice p code,
#nice li code {
  color: #0bb712;
  background: rgba(127, 226, 159, 0.48);
  display:inline-block;
  padding:0 2px;
  border-radius:2px;
  height:21px;
  line-height:22px;
}

/* 非微信代码块
* 代码块不换行 display:-webkit-box !important;
* 代码块换行 display:block;
*/
#nice .code-snippet__fix {
  background: #f7f7f7;
  border-radius: 2px;
}

#nice pre code {
  letter-spacing: 0px;
}

/*
* 表格内的单元格
* 字体大小 font-size: 16px;
* 边框 border: 1px solid #ccc;
* 内边距 padding: 5px 10px;
*/
#nice table tr th,
#nice table tr td {
  font-size: 16px;
  color: #595959;
}

#nice .footnotes {
  background: #F6EEFF;
  padding: 20px 20px 20px 20px;
  font-size: 16px;
  border: 0.8px solid #399003;
  border-radius: 6px;
  border: 1px solid #399003;
}

/* 脚注文字 */
#nice .footnote-word {
  font-weight: normal;
  color: #595959;
}

/* 脚注上标 */
#nice .footnote-ref {
  font-weight: normal;
  color: #595959;
}

/*脚注链接样式*/
#nice .footnote-item em {
  font-size: 16px;
  color: #595959;
  display: block;
}

#nice .footnotes{
  background: #fff;
  padding: 20px 20px 20px 20px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #4CAF50;
}

/* "参考资料"四个字
* 内容 content: "参考资料";
*/
#nice .footnotes-sep:before {
  content: 'Reference';
  color: #595959;
  letter-spacing: 1px;
  border-bottom: 2px solid #4CAF50;
  display: inline;
  font-size: 20px;
}

/* 参考资料编号 */
#nice .footnote-num {}

/* 参考资料文字 */
#nice .footnote-item p {
  color: #595959;
  font-weight: bold;
}

/* 参考资料解释 */
#nice .footnote-item p em {
  font-weight: normal;
}

/* 行间公式
* 最大宽度 max-width: 300% !important;
*/
#nice .block-equation svg {}

/* 行内公式
*/
#nice .inline-equation svg {}

/* 滑动图片
 */
#nice .imageflow-img {
  display: inline-block;
  width:100%;
  margin-bottom: 0;
}`;
